.custom-modal {
    width: 400px;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 8px;
  }
  
  .custom-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .custom-input-field {
    margin-bottom: 15px;
  }
  
  .custom-input-field label {
    display: block;
    margin-bottom: 5px;
  }
  
  .custom-input-field input,
  .custom-input-field textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .custom-input-field textarea {
    resize: vertical;
    height: 100px;
  }
  
  .custom-modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .button.save-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .button.cancel-button {
    background-color: #ff6666;
    color: #fff;
    border: 1px solid #cc4c4c !important; /* Mørkere rød ramme */
    transition: background-color 0.3s ease; /* Kun overgang for bakgrunnsfargen */
  }
  