/* src/components/MainLayout.css */

/* Forhindrer tekstmarkør på alle elementer unntatt input-felt og textarea */
* {
  user-select: none;
  cursor: default;
}

input, textarea {
  user-select: text;
  cursor: text;
}

/* Sikrer at ingen elementer utilsiktet får fokus */
:focus {
  outline: none;
}

/* Generelle stiler */

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Skjuler overflow på hoveddokumentet */
}
.main-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0; /* Fjern margin for å sikre full skjermvisning */
  font-family: 'Open Sans', sans-serif;
  overflow: hidden; /* Skjuler overflow på hovedlayouten */
}

.header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Sørg for at header ligger over annet innhold */
  font-family: 'Open Sans', sans-serif;
}

.back-button {
  background: none;
  border: none;
  color: #008489;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
}

.back-button:hover {
  color: #006f75 !important; /* Hover-farge */
  text-decoration: none;
}



.header-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #008489;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.header-message {
  flex: 1;
  text-align: center;
  color: #333;
  font-size: 16px;
  word-wrap: break-word;
  font-family: 'Open Sans', sans-serif;
}

/* MainLayout.css */

/* Stiler for footer */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between; /* Plasserer elementer med jevn avstand */
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Sørg for at footer ligger over annet innhold */
  outline: none;
  
}

.footer-button {
  background: none;
  border: none;
  padding: 20px;
  font-size: 24px; /* Økt font-størrelse for større ikoner */
  cursor: pointer;
  color: #008489;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Sørger for at innholdet i hver knapp er sentrert */
  padding-left: 30px; /* Eksempel: 30px padding til venstre */
  padding-right: 50px; /* Eksempel: 15px padding til høyre */
}


.footer-icon {
  font-size: 32px; /* Økt font-størrelse for større ikoner */
}

.content {
  flex: 1;
  padding: 60px 20px 160px 20px; /* Juster padding for å legge til ekstra plass nederst */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Gjør rullingen jevnere på mobile enheter */
  background-color: white;
  font-family: 'Open Sans', sans-serif;
}

/* Media queries for small screens */
@media (max-width: 600px) {
  .header-title {
    font-size: 16px;
  }

  .header-message {
    font-size: 14px;
  }

  .content {
    padding: 20px 10px 180px 10px; /* Juster padding for å unngå overlapp med header og footer */
    
  }

  .profile-container {
    max-width: 100%;
    padding: 10px;
  }

  .profile-info {
    align-items: flex-start;
    text-align: left;
  }

  .profile-avatar {
    width: 80px;
    height: 80px;
  }

  .profile-section {
    margin: 10px 0;
  }

  .profile-section h3 {
    font-size: 18px;
  }

  .profile-section input,
  .profile-section select {
    font-size: 14px;
    padding: 6px;
  }

  .button {
    font-size: 14px;
    padding: 8px;
  }

  .logout-button {
    font-size: 14px;
    padding: 8px;
  }

  .clear-cache-button {
    font-size: 14px;
    padding: 8px;
  }

  .modal {
    width: 90%;
    padding: 10px;
  }

  .modal-content {
    padding: 10px;
  }
}

/* MyProfile spesifikke stiler */
.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 80px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Open Sans', sans-serif;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile-section {
  margin: 20px 0;
}

.profile-section h3 {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
}

.profile-section label {
  display: block;
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
}

.profile-section input,
.profile-section select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
}

.button {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: #008489;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.button:hover {
  background-color: #006f75; /* Hover-farge */
  border-color: #497f7f; /* Mørkere grågrønn ramme for hover */
}

.logout-button {
  background-color: #e74c3c;
}

.logout-button:hover {
  background-color: #c0392b;
}

.clear-cache-button {
  background-color: #008489;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.clear-cache-button:hover {
  background-color: #006f75;
  border-color: #497f7f; /* Mørkere grågrønn ramme for hover */
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 80%;
  font-family: 'Open Sans', sans-serif;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
}

.modal-content h2 {
  margin-bottom: 20px;
}

.modal-content label {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
}

.modal-content input {
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
}

.cancel-button {
  background-color: #ccc;
}

.cancel-button:hover {
  background-color: #aaa;
}

/* MyCompleteTasks spesifikke stiler */
.tasks-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'Open Sans', sans-serif;
}

.task-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px;
}

.task-header {
  background: #008489;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

.task-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.task-body-left {
  flex: 1;
}

.task-body p {
  margin: 5px 0;
  font-family: 'Open Sans', sans-serif;
}

.task-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.task-footer button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.task-footer button img {
  width: 24px;
  height: 24px;
}

.task-footer img {
  width: 24px;
  height: 24px;
}

.tasks-list {
  display: flex;
  flex-direction: column;
}

.task-item {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Open Sans', sans-serif;
}

/* Stil for søkefeltet og ikonet */
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.5em;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

.search-button {
  background-color: #008489;
  border: 2px solid #008489;
  border-left: none;
  padding: 8px 10px; /* Juster padding for å gjøre knappen flush */
  height: 26px; /* Juster høyden hvis nødvendig for å matche input-feltet */
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  pointer-events: none; /* Dette gjør at elementet ikke kan klikkes */
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

/* Customer spesifikke stiler */
.customers-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'Open Sans', sans-serif;
}

/* Stil for kundeinfo */
.customer-card {
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-size: 1.5em;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
}

.customer-card h1 {
  font-size: 1.5em; /* Størrelsen på kundens navn */
  margin: 0 0 10px 0;
}

.customer-card h2 {
  font-size: 1em; /* Juster størrelsen på Customer No */
  font-weight: normal; /* Fjerner utheving */
  margin: 0 0 10px 0;
}

.customer-card p {
  margin: 5px 0;
  font-family: 'Open Sans', sans-serif;
}

.customer-overview-button {
  margin-top: 10px;
  padding: 10px;
  background-color: #008489;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.customer-overview-button:hover {
  background-color: #006f75;
  border-color: #497f7f; /* Mørkere grågrønn ramme for hover */
}

.new-customer-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0 20px 0; /* 20px nederst for å legge til luft mellom knappen og søkeresultatene */
  background-color: #008489;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.new-customer-button:hover {
  background-color: #006f75;
  border-color: #497f7f; /* Mørkere grågrønn ramme for hover */
}

/* Stil for kundens navn */
.customer-name {
  font-weight: bold;
  font-size: 1.5em; /* Samme størrelse som Customer No */
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
}

/*Stil for adressesøk */
.addresses-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'Open Sans', sans-serif;
}

.address-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
}

/*utover dette gjenbrukes stiler fra kundesøk */

/* pdf-ikon for download av rapport */
.pdf-icon {
  font-size: 24px; /* Størrelse på PDF-ikonet */
  color: #d9534f; /* Farge på PDF-ikonet */
  font-family: 'Open Sans', sans-serif;
}

.pdf-icon:hover {
  color: #c0392b;
}

.task-footer-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}

/*servicecard */
.service-address-container {
  padding: 20px;
  font-family: 'Open Sans', sans-serif;
}

.address-details, .additional-info, .future-assignments {
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.button {
  background-color: #008489; /* Matchende grønnfarge */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
}

.button:hover {
  background-color: #006f75;
  border-color: #497f7f; /* Mørkere grågrønn ramme for hover */
  
}

.assignment-list {
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
}

.assignment-item {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
}

/* Service Address spesifikke stiler */

.assignment-card {
  background: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
}

.button.cancel-button {
  background-color: #f44336;
  color: white;
}

.button.create-report-button {
  background: #008489;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.button.create-report-button:hover {
  background-color: #006f75; /* Hover-farge */
  border-color: #497f7f; /* Mørkere grågrønn ramme for hover */
}

.button.edit-button {
  background: #008489;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.button.edit-button:hover {
  background-color: #006f75; /* Hover-farge */
  border-color: #497f7f; /* Mørkere grågrønn ramme for hover */
}
.button.get-directions-button {
  margin-top: 10px;
}

.button.get-directions-button:hover {
  background-color: #006f75; /* Hover-farge */
  border-color: #497f7f; /* Mørkere grågrønn ramme for hover */
  }

.button.upload-button {
  background-color: #008cba;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif;
}

.button-group button {
  margin-right: 10px; /* Legger til 10px mellomrom til høyre for hver knapp */
}

.button-group button {
  margin-right: 10px; /* Legger til 10px mellomrom til høyre for hver knapp */
  margin-left: 10px; /* Legger til 10px mellomrom til venstre for hver knapp */
}

.camera-icon {
  font-size: 4em;
  color: #ccc;
}

.close-modal-button {
  background: #ff0000;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.date-align-right {
  flex-shrink: 0;
  margin-left: auto;
  font-family: 'Open Sans', sans-serif;
}

.expand-button {
  background: #008489;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.expand-button:hover {
background-color: #006f75; /* Hover-farge */
border-color: #497f7f; /* Mørkere grågrønn ramme for hover */
}
.expandable-content {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
}

.expandable-section {
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.floorplan-card {
  background: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
}

.google-map {
  width: 300px;
  height: 200px;
}

.green-check {
  color: green;
}

.input-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.5em;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

.map-and-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  font-family: 'Open Sans', sans-serif;
}

.modal-image {
  width: 100%;
  height: auto;
}

.pdf-icon {
  cursor: pointer;
  color: red;
  font-size: 24px;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
}

.red-cross {
  color: grey;
}

.service-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-bottom: 80px;
  font-family: 'Open Sans', sans-serif;
}

.service-card-body {
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
}

.service-card-container {
  margin: 0 auto;
  max-width: 100%;
}

.service-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.service-card-info {
  flex: 1;
  font-family: 'Open Sans', sans-serif;
}

.table-cell {
  flex: 1;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
}

.table-cell-right {
  flex: 1;
  text-align: right;
  font-family: 'Open Sans', sans-serif;
}

.table-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  font-family: 'Open Sans', sans-serif;
}

.table-section {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
}

.upload-form {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
}

.upload-image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 1px dashed #ccc;
  margin-bottom: 20px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
}

.upload-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Open Sans', sans-serif;
}

.upload-modal-content {
  width: 100%;
  max-width: 800px;
  height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.visit-card {
  background: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
}

.visit-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.visit-card-header {
  display: flex;
  justify-content: space-between;
}

/* customer overview */
.service-card-info {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
}

.service-card-info p {
  display: left;
  justify-content: space-between;
}

.service-card-info p strong {
  margin-right: 10px;
}

/* Highlighting mandatory fields */
.ml-mandatory {
  background-color: #ffffe0;
}

.ml-filled {
  background-color: #defade;
}
