/* src/pages/MainMenu.css */

.main-menu {
  padding: 20px;
}

.greeting {
  text-align: left !important;
  margin-bottom: 20px;
}

.greeting h1 {
  font-size: 24px;
  color: #333;
}

.greeting p {
  font-size: 16px;
  color: #666;
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr)); /* Sett minimumsbredden til 100px */
  gap: 20px;
}

.menu-item {
  position: relative; /* Add this line */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 80px; /* Juster høyden som du ønsker */
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  max-width: 140px; /* Sett maksimal bredde for boksene */
}

.menu-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.menu-item span {
  font-size: 14px;
  color: #333;
}

.badge {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
  background: #ff4c4c; /* Lighter red color */
  color: white !important; /* Set text color to white */
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold; /* Set font weight to bold */
  display: flex;
  align-items: center;
  justify-content: center;
}