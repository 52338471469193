.agreement-new-treat-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.agreement-new-treat-form {
  display: flex;
  flex-direction: column;
}

.treatment-selection {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.treatment-selection label {
  font-weight: bold;
  color: #008489;
  margin-right: 10px;
  font-size: 1.5em;
}

.treatment-selection select {
  width: 100%;
  padding: 12px;
  font-size: 1.5em;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.treatment-list {
  margin-bottom: 20px;
}

.treatment-item {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #ffffff;
  font-size: 1.2em;
}

.treatment-item h3 {
  margin: 0 0 10px 0;
  font-size: 1.5em;
  color: #333;
}

.treatment-item label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.treatment-item label input {
  width: 20%;
  padding: 8px;
  font-size: 1.2em;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: Right;
}

.treatment-item input[type="checkbox"] {
  transform: scale(1.8);
}

.treatment-item .delete-button {
  background-color: #d9534f;
  color: white;
  padding: 10px 20px;
  font-size: 1.em;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

.treatment-item .delete-button:hover {
  background-color: #c9302c;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-actions button {
  background-color: #008489;
  color: white;
  padding: 15px 25px;
  font-size: 1.2em;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.form-actions button:hover {
  background-color: #006f75;
}

@media (max-width: 600px) {
  .agreement-new-treat-container {
    padding: 15px;
  }

  .treatment-item label input {
    width: 100%;
  }

  .form-actions {
    flex-direction: column;
  }

  .form-actions button {
    width: 100%;
    margin-bottom: 10px;
  }

  .form-actions button:last-child {
    margin-bottom: 0;
  }
}
