.address-card-wrapper {
    margin-bottom: 20px; /* Justerer mellomrommet mellom kortene */
  }
  
  .address-card {
    position: relative; /* For å plassere badgen øverst til høyre */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
  }
  
  .address-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .ac-badge {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 50px; /* Økt bredde for å plassere tekst */
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b0b0b0 !important;/* Tynn grå border */
    font-size: 16px !important;
    color: black !important; /* Sort skrift */
    font-size: 12px; /* Mindre skriftstørrelse for å passe inn i badgen */
    transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
  }
  
  .badge-red {
    background-color: darksalmon; 
    
  }
  
  .badge-yellow {
    background-color: gold; /* Mild gul farge */
  }
  
  .badge-green {
    background-color: darkseagreen; /* Mild grønn farge */
  }
  
  .distance-info {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .address-card p strong {
    font-size: 18px; /* Større fontstørrelse for navn */
  }
  