.filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .filter-container .search-box {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .year-filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .year-filter-label {
    font-size: 16px;
    color: #008489; /* Same green color used elsewhere on the page */
  }
  
  .filter-container {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
  }
  
  .filter-dropdown {
    padding: 10px; /* Innvendig marg for bedre klikkflate */
    border: 1px solid #ddd; /* Grense rundt dropdown */
    border-radius: 5px; /* Runde kanter */
    background-color: #fff; /* Hvit bakgrunn */
    color: #333; /* Tekstfarge */
    font-size: 16px; /* Minimum fontstørrelse for å unngå zooming på mobil */
    appearance: none; /* Fjern standard dropdown-stil */
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23333" viewBox="0 0 24 24"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3C/svg%3E'); /* Tilpasset pil */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    height: 40px; /* Ensartet høyde med andre elementer */
    box-sizing: border-box; /* Inkluder padding i bredde/høyde */
    max-width: 200px; /* Begrens bredden for penere utseende */
}

.filter-dropdown:focus {
    outline: none; /* Fjern standard fokusramme */
    border-color: #008489; /* Endre kantfarge ved fokus */
    box-shadow: 0 0 3px #008489; /* Svak glød ved fokus */
}

  
  .tasks-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .task-card {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    transition: box-shadow 0.3s ease;
  }
  
  .task-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .task-header h2 {
    font-size: 18px;
    font-weight: bold;
  }
  
  .task-header span {
    font-size: 18px;
    color: #fff;
  }
  
  .task-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
  }
  
  .task-body-left {
    flex: 1;
  }
  
  .task-footer {
    display: flex;
    gap: 10px;
  }
  
  .task-footer-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: none !important; /* No background */
    transition: background-color 0.3s ease;
  }
  
  .task-footer-button:hover .pdf-icon {
    color: #e60000; /* Darker red on hover */
  }
  
  .pdf-icon {
    font-size: 40px;
    color: #ff0000; /* Adobe red color */
  }
  
  @media (max-width: 768px) {
    .task-body {
      flex-direction: column;
    }
  }
  