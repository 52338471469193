/* src/global.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
}

button {
  font-family: inherit;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #008489;
  color: white;
  border: 1px solid #5ca4a4; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

button:hover {
  color: white;
}

h1, h2, h3 {
  margin: 0;
}

.container {
  padding: 20px;
  margin: auto;
}

.common-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #008489; /* Riktig farge */
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.common-button:hover {
  background-color: #006f75; /* Riktig farge */
  color: white;
}

.logout-button {
  background-color: #f44336;
  color: white;
}

.logout-button:hover {
  background-color: #d32f2f;
}
