.cp-checkpoint-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cp-checkpoint-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
}

.cp-checkpoint-card p {
    margin: 5px 0;
}
