.page-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .page-header h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .page-header p {
    font-size: 1em;
    color: #555;
  }
  