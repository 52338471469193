.inspection-details-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .inspection-details-container {
    padding: 15px;
  }
}

.inspection-field {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 16px;
  display: block;
}

.inspection-input,
.inspection-textarea {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  transition: border-color 0.3s ease;
}

.inspection-input:focus,
.inspection-textarea:focus {
  border-color: #007bff;
  outline: none;
}

.inspection-input-required {
  border-color: red;
}

.photo-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  width: 100%;
}

@media (max-width: 768px) {
  .photo-list {
    justify-items: center;
  }
}

.photo-item {
  width: 100%;
  max-width: 300px;
  border: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  transition: transform 0.3s ease;
}

.photo-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.photo-preview-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.photo-rotation-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.photo-button {
  padding: 12px;
  border-radius: 50%;
  background-color: #008489;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.photo-button:hover {
  background-color: #006f75;
}

.photo-textarea {
  margin-top: 10px;
  width: calc(100% - 20px);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  transition: border-color 0.3s ease;
}

.photo-textarea:focus {
  border-color: #007bff;
  outline: none;
}

.photo-delete-button {
  margin-top: 10px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.photo-delete-button:hover {
  background-color: #b02a37;
}

.photo-add-button {
  padding: 12px 24px;
  background-color: #008489;
  color: #fff;
  margin-top: 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.photo-add-button:hover {
  background-color: #006f75;
}

.inspection-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .inspection-buttons {
    flex-direction: column;
    gap: 10px;
  }
}

.inspection-button {
  padding: 12px 24px;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.inspection-button-back {
  background-color: #6c757d;
}

.inspection-button-back:hover {
  background-color: #5a6268;
}

.inspection-button-complete {
  background-color: #008489;
}

.inspection-button-complete:hover {
  background-color: #006f75;
}
