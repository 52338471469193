.app-version {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-align: center;
  margin-top: 60px; /* Legger til avstand over app-versjonen */
  margin-bottom: 20px; /* Legger til avstand under app-versjonen */
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Halvgjennomsiktig bakgrunn for å fremheve modal */
  z-index: 1000;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Sett bredde til en viss prosent for bedre skalerbarhet */
  max-width: 600px; /* Maksimal bredde for modalen */
  background: #fff; /* Hvit bakgrunn for modal */
  border-radius: 8px; /* Legg til runde hjørner */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Skygge for bedre kontrast */
  padding: 20px; /* Gjør padding større for bedre innholdsmargin */
  overflow-y: auto; /* Gjør modalen rullbar */
  max-height: 90vh; /* Sørg for at modal ikke blir større enn skjermen */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Halvgjennomsiktig bakgrunn for å fremheve modal */
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-content h2 {
  margin-bottom: 15px;
  text-align: center;
}

.cancel-button {
  background: #ff4c4c; /* Rød bakgrunn for avbryt/tilbake-knappen */
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

.cancel-button:hover {
  background: #e04343; /* Litt mørkere rød ved hover */
}