/* Preview.css */

/* Reduser global skriftstørrelse for å tilpasse til iPhone */
body {
  font-family: 'Open Sans', 'Arial', 'sans-serif';
  font-size: 12px; /* Redusert fra 14px til 12px */
  margin: 0;
  padding: 0;
}

#pageContent {
    margin: 0 25px 25px;
}

.preview-main_wrapper {
    margin-bottom: 25px;
}

.preview-rw {
    display: table;
    clear: both;
    width: 100%;
}

.preview-cl {
    display: table-cell;
    vertical-align: top;
}

/* Set widths for the table and image columns */
.preview-cl70 {
    width: 30%;
}

.preview-cl30 {
    width: 30%;
}

/* Scale image within the 30% column */
.preview-cl30 img {
    width: 100%;
    height: auto;
}

.preview-cntable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #000;
    border-radius: 4px;
}

.preview-cntable tr td, .preview-cntable tr th {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    text-align: left;
    padding: 4px;
}

.preview-cntable tr td:last-child, .preview-cntable tr th:last-child {
    border-right: none;
}

.preview-cntable tr:last-child td, .preview-cntable tr:last-child th {
    border-bottom: none;
}

.preview-cntable thead tr:last-child td, .preview-cntable thead tr:last-child th {
    border-bottom: 1px solid #000;
}

.preview-grey-head {
    padding: 7px;
    background-color: #ddd;
}

.preview-grey-stripe {
    background-color: #ddd;
    height: 10px;
}

.preview-transparent-stripe {
    background-color: #ffffff;
    height: 20px;
}

.preview-green-text {
    color: #3d405b;
}

.preview-cr-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview-cr-box {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview-cr-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

.preview-cr-button {
  padding: 15px 25px; /* Økt padding for bedre touch-opplevelse */
  font-size: 1.2em; /* Økt skriftstørrelse */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  flex: 1;
  margin: 0 10px;
}

.preview-cr-back-button {
  background-color: #ccc; /* Lysegrå */
  color: #333; /* Mørkere tekst */
  border: 1px solid #999; /* Mørkere grå ramme */
  transition: background-color 0.3s ease; /* Jevn overgang */
}

.preview-cr-save-button {
  background-color: #008489;
  color: #fff;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.preview-cr-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.preview-cr-modal-content {
  background-color: #fff;
  margin: auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 600px;
  position: relative;
}

.preview-cr-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.preview-cr-close:hover,
.preview-cr-close:focus {
  color: #000;
}

.preview-cr-signature-group {
  margin-bottom: 30px;
}

.preview-cr-signature-group h3 {
  margin-bottom: 10px;
}

.preview-cr-signature-canvas {
  border: 2px dashed #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  width: 100%;
  height: 150px;
}

.preview-cr-reset-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.preview-cr-reset-button:hover {
  background-color: #bbb;
}

.preview-cr-modal-buttons {
  display: flex;
  justify-content: space-between;
}

.preview-cr-modal-buttons button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.preview-cr-modal-buttons button:first-child {
  background-color: #ff6666;
  color: white;
}

.preview-cr-modal-buttons button:first-child:hover {
  opacity: 0.8;
}

.preview-cr-modal-buttons button:last-child {
  background-color: #008489;
  color: white;
}

.preview-cr-modal-buttons button:last-child:hover {
  background-color: #006f75;
}

.missing-fields-modal {
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.missing-fields-modal {
  max-width: 500px;
  width: 90%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* Legg til en enkel fade-in-animasjon */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
/* Modal Title */
.modal-title {
  font-size: 1.8em; /* Gjør tittelen litt større */
  font-weight: bold;
  margin-bottom: 15px; /* Øk avstanden under tittelen */
  text-align: center; /* Sentrer tittelen */
  color: #333; /* Mørkere grå */
}

/* Modal Message */
.modal-message {
  font-size: 1.2rem; /* Litt større tekst for lesbarhet */
  margin-bottom: 15px;
  color: #555;
  text-align: center; /* Sentrer teksten */
}

/* Modal List */
.modal-list {
  list-style-type: disc;
  margin: 15px auto; /* Sentrer listen horisontalt */
  padding-left: 30px; /* Øk innrykket */
  text-align: left;
  max-width: 400px; /* Begrens bredden for bedre lesbarhet */
}

/* Modal List Items */
.modal-list-item {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px; /* Legg til mer avstand mellom elementene */
  line-height: 1.5; /* Øk linjeavstanden */
}

/* Modal Buttons Container */
.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 15px; /* Øk avstanden mellom knappene */
  margin-top: 20px; /* Legg til avstand over knappene */
}

/* Close Button */
.modal-close-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 25px; /* Juster padding for større knapp */
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-close-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Legg til en liten "hover"-effekt */
}
