.calendar-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden; /* Prevent content overflow causing scrollbars */
  }

  .calendar-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    
  }

  .calendar-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .calendar-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #008489; /* Updated color */
    transition: color 0.3s ease;
}

  .calendar-button:hover {
    color: #006f75;
  }

  .events-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    overflow-y: auto;
    max-height: 500px; /* Adjust as needed for responsive view */
    border-top: 2px solid #008489; /* Add a bottom border in the same color as the month name */
  }

  .event-item {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px; /* Restore rounded corners */
    background-color: #f4f4f4;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%; /* Set a fixed width for event-item to avoid resizing */
    box-sizing: border-box; /* Include padding and border in width calculation */
  }

  .event-item .event-date {
    min-width: 70px; /* Ensure consistent width for the date area */
    margin-right: 20px;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .event-item .event-date-number {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .event-item .event-day {
    font-size: 1rem;
    text-transform: uppercase;
    color: #777;
  }

  .event-item .event-details-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .event-item .event-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    background-color: inherit; /* Use the event-item's background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px; /* Add margin between event boxes */
    width: calc(95%); /* Ensure event-details is slightly smaller than event-item */
    box-sizing: border-box;
  }

  .event-item .event-box {
    width: calc(100% - 20px); /* Ensure event box fits within the event item */
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .event-box {
    position: relative; 
  }
  .datepicker-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    transition: all 0.3s ease;
  }

  .datepicker {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
  }

  .datepicker-container.active .datepicker {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .react-datepicker__day--selected {
    background-color: #008489 !important; /* Updated color */
    color: #fff !important;
}

.event-day-number {
    color: #008489; /* Updated color for the date numbers */
}

.status-icon-container {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.status-icon {
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 5px;
  
}

  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .calendar-header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .calendar-title {
      font-size: 1.2rem;
    }

    .calendar-button {
      font-size: 1.2rem;
      margin-top: 0;
    }

    .event-item {
      flex-direction: row;
      align-items: center;
      padding: 15px;
      border-radius: 10px; /* Restore rounded corners */
      margin-bottom: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      width: 100%; /* Set a fixed width for event-item to avoid resizing */
      box-sizing: border-box; /* Include padding and border in width calculation */
    }

    .event-item .event-date {
      min-width: 70px;
      font-size: 2rem;
      text-align: center;
      margin-right: 20px;
    }

    .event-item .event-day {
      font-size: 0.8rem;
    }

    .event-item .event-details {
      font-size: 0.9rem;
      border-radius: 10px;
      background-color: inherit; /* Use the event-item's background color */
      margin-bottom: 10px;
      width: calc(95%); /* Ensure event-details is slightly smaller than event-item */
      box-sizing: border-box;
    }

    .datepicker {
      position: static;
      width: 100%;
      margin-top: 15px;
    }
  }

  /* Media query for large screens */
  @media (min-width: 1024px) {
    .calendar-header {
      padding: 20px 0;
    }

    .event-item {
      padding: 20px;
      border-radius: 10px; /* Restore rounded corners */
      margin-bottom: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      width: 100%; /* Set a fixed width for event-item to avoid resizing */
      box-sizing: border-box; /* Include padding and border in width calculation */
    }

    .event-item .event-date {
      font-size: 3rem;
    }

    .event-item .event-day {
      font-size: 1rem;
    }
  }

  /* General adjustments for a more flexible design */
  .scrollable {
    overflow-y: auto;
    max-height: 500px;
  }
