.agreement-preview-container {
    padding: 80px;
  }
  
  .agreement-preview-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .agreement-preview-table th,
  .agreement-preview-table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }
  
  .table-title {
    text-align: center;
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .customer-info-table,
  .prices-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .customer-info-table th,
  .customer-info-table td,
  .prices-table th,
  .prices-table td {
    border: 1px solid #000;
    padding: 8px;
  }
  
  .gray-background {
    background-color: #f0f0f0;
  }
  
  .company-info {
    text-align: center;
  }
  
  .company-logo {
    max-width: 100%;
    height: auto;
  }
  
  .signature-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .signature-box {
    border: 1px solid #000;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .sigCanvas {
    border: 1px solid #000;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .save-button {
    background-color: #008489;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
  }
  
  .save-button:hover {
    background-color: #006f75;
  }
  
  /* Nye klasser for signerinsdelen */
/* AgreementNewPreview.css */

.agreement-preview-container {
  padding-bottom: 80px;
}

.checkbox-section {
  margin-top: 20px;
}

.button-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.sign-button, .submit-button {
  background-color: #008489;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.sign-button:hover, .submit-button:hover {
  background-color: #006f75;
}

.modal-agreement {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content-agreement {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  margin: auto;
  position: relative;
}

.modal-close-agreement {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-close-agreement:hover,
.modal-close-agreement:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.signature-section-agreement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signature-box-agreement {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.sigCanvas {
  border: 1px solid #000;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.modal-button-section-agreement {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Sørg for at knappene tar opp hele bredden til containeren */
  max-width: 500px; /* Sett maks bredde til å matche signaturboksene */
  gap: 20px; /* Legger til avstand mellom knappene */
  margin-top: 20px; /* Legg til margin over knappene for bedre luft */
}

.save-agr-button,
.cancel-agr-button {
  flex: 1; /* Sørg for at begge knappene tar opp lik plass */
  padding: 20px; /* Samme padding for begge knappene */
  text-align: center;
  box-sizing: border-box; /* Inkluder padding i beregningen av bredde */
}

.save-agr-button {
  background-color: #008489;
  color: white;
  padding: 20px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Legg til bredde for å sikre at knappene har samme størrelse */
  box-sizing: border-box; /* Sørg for at padding inkluderes i bredden */
}

.cancel-agr-button {
  background-color: #e74c3c;
  color: white;
  padding: 20px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Legg til samme bredde som save-button */
  box-sizing: border-box; /* Sørg for at padding inkluderes i bredden */
}

.save-agr-button:hover {
  background-color: #006f75;
}

.cancel-agr-button:hover {
  background-color: #c0392b;
}

@media screen and (max-width: 600px) {
  .modal-content-agreement {
    width: 95%;
  }
  .modal-button-section-agreement {
    flex-direction: column;
    align-items: center;
  }
  .save-button, .cancel-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
