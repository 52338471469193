.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px; /* Add some space between elements */
}

.search-box {
  height: 46px; /* Match høyden til filter-dropdown */
  padding: 10px;
  font-size: 1.2em;
  box-sizing: border-box;
}
.filter-dropdown {
  height: 40px; /* Ensartet høyde med søkefeltet */
  line-height: 1.2; /* Sentraliser tekst vertikalt */
  padding: 10px; /* Lik padding som søkefeltet */
  font-size: 1.2em; /* Lik fontstørrelse som søkefeltet */
  border: 1px solid #ddd; /* Konsistent grense */
  border-radius: 5px; /* Runde kanter */
  box-sizing: border-box; /* Inkluder padding og border i høyden */
  -webkit-appearance: none; /* Fjern standard stil for Safari */
  -moz-appearance: none; /* Fjern standard stil for Firefox */
  appearance: none; /* Fjern standard stil for andre nettlesere */
  background-color: #fff; /* Hvit bakgrunn */
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23333" viewBox="0 0 24 24"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 10px center; /* Plasser pilen riktig */
  background-size: 12px;
}


.filter-dropdown {
  -webkit-appearance: none; /* For Safari/Chrome */
  -moz-appearance: none; /* For Firefox */
  appearance: none;
}

.filter-dropdown:focus {
  outline: none; /* Fjern standard fokusramme */
  border-color: #008489; /* Endre kantfarge ved fokus */
  box-shadow: 0 0 3px #008489; /* Svak glød ved fokus */
}

.filter-button {
  padding: 10px 20px;
  background-color: #008489;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  height: 46px;
  box-sizing: border-box;
}

.filter-button:hover {
  background-color: #006f75;
}

.tasks-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.task-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.task-card:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #008489;
  color: white;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.task-header h2 {
  font-size: 18px;
  font-weight: bold;
}

.task-header span {
  font-size: 18px;
  color: white;
}

.task-body {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f9f9f9;
}

.task-body-left {
  flex: 1;
}

.task-footer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.task-footer-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: background-color 0.3s ease;
}

.resume-button {
  background-color: #008489;
}

.resume-button:hover {
  background-color: #006f75;
}

.delete-button {
  background-color: #ff6666;
  color: #fff;
  border: 1px solid #cc4c4c; /* Mørkere rød ramme */
  transition: background-color 0.3s ease; /* Kun overgang for bakgrunnsfargen */
  margin-left: 10px;
}

.delete-button:hover {
  background-color: #e04343 !important;
}

@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    align-items: stretch;
  }

  .search-box,
  .filter-dropdown,
  .filter-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .task-body {
    flex-direction: column;
    align-items: flex-start;
  }

  .task-footer {
    justify-content: flex-start;
  }
}

.edit-button {
  background-color: #008489 !important;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #006f75;
}

