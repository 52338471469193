/* Existing classes for reference */
/* ... other existing CSS code ... */

/* New unique classes for Efforts Summary alignment */

.es-container {
  width: 90vh;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.es-box {
  background-color: #f9f9f9;
  padding: 20px;
  max-width: 80vh; /* Reduced width */
  border-radius: 10px;
  margin-bottom: 20px;
  
}

.es-heading {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.es-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.es-checkbox-container label {
  font-size: 1em;
  color: #333;
}

.es-checkbox-container input {
  margin-right: 10px;
}

.es-datepicker-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.es-datepicker {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.es-visit-type-dropdown {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.es-effort-container {
  margin-top: 20px;
}

.es-effort-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.es-effort-icon {
  font-size: 2em;
  color: #008489;
  margin-bottom: 10px;
}

.es-effort-title {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 5px;
}

.es-effort-time {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.es-effort-time div {
  flex: 1;
}

.es-effort-time label {
  display: block;
  margin-bottom: 5px;
}

.es-effort-input-container {
  width: 150px; /* Adjusted to match the width of the time fields */
}

.es-effort-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  margin-bottom: 10px;
}

.es-effort-textarea-container {
  width: 100%;
  max-width: 650px; /* Adjusted to match the width of the entire container minus the icon space */
}

.es-effort-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  height: 100px;
}

.es-effort-buttons {
  display: flex;
  justify-content: flex-start; /* Align to the left */
  gap: 10px; /* Add space between buttons */
}

.es-effort-button {
  padding: 10px 20px;
  background-color: #008489;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.es-effort-back-button {
  background-color: #ccc;
}

.es-effort-save-button {
  background-color: #008489;
}

.es-effort-button:hover {
  opacity: 0.8;
}

/* Highlighting mandatory fields */
.es-mandatory {
  background-color: #ffffe0;
}

.es-filled {
  background-color: #defade;
}


/* Responsive Design */
@media (max-width: 768px) {
  .es-effort-time {
    flex-direction: column;
  }

  .es-effort-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .es-effort-button {
    width: 100%;
    text-align: center;
  }
}
