.task-footer-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white; /* Ensure text is white by default */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  .resume-button {
    background-color: #008489 !important;
    color: white;
  }
  
  .resume-button:hover {
    background-color: #006f75;
  }
  
  .delete-button {
    background-color: #ff4c4c !important; /* Mild red color */
    color: white;
    margin-left: 10px;
    border: 1px solid #cc4c4c !important; /* Mørkere rød ramme */
    transition: background-color 0.3s ease !important; /* Kun overgang for bakgrunnsfargen */
  }
  
  .delete-button:hover {
    background-color: #e04343 !important; /* Darker mild red color on hover */
  }
  
  .task-footer button {
    background-color: #008489; /* Ensure buttons have green background */
    color: white;
    
  }
  