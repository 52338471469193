/* src/pages/Login.css */

.login-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  background-color: #fff;
}

.login-container {
  max-width: 400px;
  width: 85%; /* Justert bredde */
  padding: 25px; /* Økt padding */
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  text-align: center;
  z-index: 1;
  margin-top: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-container h1 {
  margin-bottom: 25px;
  font-size: 2.2rem; /* Økt skriftstørrelse */
}

.login-container input[type="text"],
.login-container input[type="password"] {
  width: 100%; /* Justert bredde for å matche knappene */
  padding: 1.2rem; /* Økt padding */
  margin: 12px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1.3rem; /* Økt skriftstørrelse */
  box-sizing: border-box;
}

.login-container button {
  width: 100%;
  padding: 1.2rem; /* Økt padding */
  margin: 12px 0;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #008489;
  color: white;
  font-size: 1.4rem; /* Økt skriftstørrelse */
}

.login-container button:hover {
  background-color: #006f75;
}

.login-container label {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 100%;
  margin-top: 12px;
  text-align: left;
  font-size: 1.2rem; /* Økt skriftstørrelse */
  color: #333;
  flex-direction: row;
}

.login-container label input[type="checkbox"] {
  margin-right: 10px;
  width: 1.5rem; /* Større checkbox */
  height: 1.5rem; /* Større checkbox */
  flex-shrink: 0;
}

.login-container .error {
  color: red;
  font-size: 1.2rem; /* Økt skriftstørrelse */
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.8rem; /* Økt skriftstørrelse */
}

.modal-content label {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  font-size: 1.2rem; /* Økt skriftstørrelse */
}

.modal-content input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 1.2rem; /* Økt skriftstørrelse */
}

.modal-content .button {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #008489;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1.3rem; /* Økt skriftstørrelse */
}

.modal-content .button:hover {
  background-color: #006f75;
}

.modal-content .cancel-button {
  background-color: #f44336;
}

.modal-content .cancel-button:hover {
  background-color: #d32f2f;
}

.modal-content .message {
  margin-top: 10px;
  color: green;
  font-size: 1.2rem; /* Økt skriftstørrelse */
}

.footer {
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 1rem; /* Økt skriftstørrelse */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Media Queries for å justere utseende på forskjellige enheter */
@media (max-width: 767px) {
  .login-container {
    width: 95%; /* Justerer boksen på små skjermer */
    margin-top: 50px;
  }

  .login-container h1 {
    font-size: 2rem; /* Tilpasser tittelen på små skjermer */
  }

  .login-container input[type="text"],
  .login-container input[type="password"] {
    font-size: 1.1rem; /* Tilpasser skriftstørrelsen */
    padding: 1rem; /* Justerer padding */
  }

  .login-container button {
    font-size: 1.2rem; /* Tilpasser knappestørrelsen */
    padding: 1rem; /* Justerer padding */
  }

  .footer {
    font-size: 0.9rem; /* Justerer footer-størrelsen på små skjermer */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .login-container {
    width: 75%; /* Smalere boks for nettbrett */
    margin-top: 75px;
  }

  .login-container h1 {
    font-size: 2.2rem; /* Justerer tittelen på nettbrett */
  }

  .footer {
    font-size: 0.95rem; /* Justerer footer-størrelsen på nettbrett */
  }
}
