/* CreateReport.css */
/* Sørg for at body og html tillater rulling */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Forhindre horisontal rulling */
  overflow-y: auto; /* Tillater vertikal rulling */
}

/* Sørg for at hovedcontaineren tillater rulling hvis nødvendig */
.cr-container {
  padding: 50px;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Sørg for at containeren fyller hele visningshøyden */
  box-sizing: border-box;
  overflow-y: auto; /* Tillater rulling om innholdet overstiger høyden */
}

.cr-box {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 40px !important;
  width: 100%;
  max-width: 80vh;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.cr-box-large {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.cr-heading {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
}

.cr-select-container {
  width: 100%;
}

.cr-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 1.2em; /* Økt skriftstørrelse */
}

.cr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.cr-modal-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  max-height: 300px; /* Sett en maksimal høyde for listen */
  overflow-y: auto; /* Gjør listen scrollbar dersom den overstiger maksimal høyde */
  padding-right: 10px; /* Ekstra padding for å unngå at scrollelisten overlapper teksten */
}

.cr-modal-item {
  padding: 10px;
  font-size: 1.2em; /* Økt skriftstørrelse */
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.cr-modal-item:hover {
  background: #f0f0f0;
}

.cr-cancel-button {
  background-color: #ff6666;
  color: #fff;
  padding: 10px 20px;
  font-size: 1.2em; /* Økt skriftstørrelse */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  width: 100%;
}

.cr-cancel-button:hover {
  background-color: #cc0000;
}

.cr-select {
  width: 100%;
  padding: 15px; /* Øk padding for bedre touch-opplevelse */
  font-size: 1.2rem; 
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  background-color: #fff;
  color: #333;
}

.cr-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.cr-button {
  padding: 15px 25px; /* Økt padding for bedre touch-opplevelse */
  font-size: 1.2em; /* Økt skriftstørrelse */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  flex: 1;
  margin: 0 10px;
}

.cr-back-button {
  background-color: #ccc;
  color: #333;
  border: 1px solid #999; /* Mørkere grå ramme */
  transition: background-color 0.3s ease; /* Jevn overgang */
}

.cr-save-button {
  background-color: #008489;
  color: #fff;
  border: 1px solid #5ca4a4 !important; /* Lys grågrønn ramme */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.cr-cancel-button {
  background-color: #ff6666;
  color: #fff;
  border: 1px solid #cc4c4c; /* Mørkere rød ramme */
  transition: background-color 0.3s ease; /* Kun overgang for bakgrunnsfargen */
}

.cr-back-button:hover, .cr-save-button:hover, .cr-cancel-button:hover {
  opacity: 0.8;
}

.cr-add-button {
  background-color: #008489;
  color: #fff;
}

.cr-add-button:hover {
  background-color: #006f75;
}

.cr-textarea-container {
  position: relative;
  width: 100%;
}

.cr-textarea {
  width: 90%;
  height: 200px;
  z-index: 1; /* Sørger for at textarea ligger under ikonet */
  padding: 10px; /* Justerer padding for bedre lesbarhet */
  font-size: 1.5em; /* Øker skriftstørrelsen */
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  resize: none;
  box-sizing: border-box;
}

.cr-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.cr-radio-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  font-size: 1.2em;
  border-bottom: 1px solid #ddd;
}

.cr-radio-label:last-child {
  border-bottom: none;
}

.cr-radio-label input {
  display: none;
}

.cr-radio-custom {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ddd;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.cr-radio-label input:checked + .cr-radio-custom {
  background-color: #008489;
  border-color: #008489;
}

.cr-radio-custom:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

.cr-radio-label input:checked + .cr-radio-custom:after {
  transform: translate(-50%, -50%) scale(1);
}

.cr-image-item {
  margin-bottom: 20px;
}

.cr-preview-image {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.cr-delete-button {
  background-color: #ff6666;
  color: #fff;
}

.cr-delete-button:hover {
  background-color: #cc0000;
}

.cr-image-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(35vh * 0.75);
  border: 2px dashed #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}

.cr-camera-icon {
  font-size: 14em;
  color: #ccc;
}

.cr-customtext-icon {
  position: absolute;
  top: 20px;
  right: 7px;
  z-index: 1000;
  font-size: 4em;
  cursor: pointer;
  color: #008489;
}

.cr-customtext-icon:hover {
  color: #006f75;
}
.cr-image-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cr-rotation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.cr-preview-image {
  width: 30vh;
  height: calc(30vh * 0.75);
  object-fit: cover;
  margin-bottom: 10px;
}

.cr-product-details {
  text-align: left; /* Justerer tekstene til venstre */
  width: 100%; /* Sørger for at elementene fyller hele bredden */
  font-size: 1.8em;
}

.cr-input-group label {
  padding-top: 20px;
  padding-bottom: 10px;
  display: block; /* Sørger for at label står alene på en linje */
  font-size: 1.2rem; /* Øker skriftstørrelsen for label */
  margin-bottom: 5px; /* Legger til margin under label */
}
.cr-input-group textarea {
  font-size: 1.2rem; /* Øker skriftstørrelsen */
  padding: 12px; /* Øker padding for bedre lesbarhet */
  width: 100%; /* Sørger for at inputfeltene fyller hele bredden */
  box-sizing: border-box; /* Sørger for at padding ikke utvider feltet */
}

/* Øk størrelsen på Amount-feltet (input[type="number"]) */
.cr-amount-input {
  font-size: 1.2rem; /* Øk skriftstørrelsen */
  padding: 12px; /* Øk padding for bedre lesbarhet */
  width: 100%; /* Sørg for at inputfeltet fyller hele bredden */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Sikrer at padding ikke utvider feltet */
}