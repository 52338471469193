.update-info-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
  }
  
  .update-info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Mørkere bakgrunn bak modal */
    z-index: 1000; /* Sørg for at den ligger over innholdet men under modalen */
  }
  
  .update-info-textarea {
    width: 95%;
    height: 100px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    font-size: 16px;
    resize: none;
  }
  
  .update-info-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: #333;
    margin-right: 10px;
    border: none;
  }
  
  .cancel-button:hover {
    opacity: 0.8;
  }
  
  .save-button {
    background-color: #008489;
    color: #fff;
    border: none;
  }
  
  .save-button:hover {
    background-color: #006f75;
  }
  