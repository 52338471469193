
.expandable-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.floorplan-card {
  display: flex; /* Gjør kortet til en fleksbeholder */
  justify-content: space-between; /* Fordel innholdet jevnt med plass mellom */
  align-items: center; /* Sentraliser innholdet vertikalt */
  padding: 10px; /* Gi litt padding */
  border: 1px solid #ccc; /* Gi en kantlinje for å skille kortene */
  border-radius: 5px; /* Gjør kantene avrundet */
  margin-bottom: 10px; /* Gi litt mellomrom mellom kortene */
  background-color: #f9f9f9; /* Lys bakgrunnsfarge */
}

.floorplan-details {
  flex-grow: 1; /* Sørg for at denne delen tar opp mest mulig plass */
}