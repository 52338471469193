.schedule-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .schedule-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Mørkere bakgrunn bak modal */
    z-index: 1000; /* Sørg for at den ligger over innholdet men under modalen */
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .date-picker {
    width: 96%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .dropdown {
    width: 100%;
  }
   
  .date-picker,
  .dropdown {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  
  .save-sc-button,
  .cancel-sc-button {
    width: 90%; /* Bruk en prosentandel for bredden */
    max-width: 150px; /* Sett en maksimal bredde for større skjermer */
    min-width: 100px; /* Sett en minimumsbredde for mindre skjermer */
    height: auto !; /* La høyden tilpasse seg innholdet */
    padding: 10px;
    text-align: center;
    display: inline-block;
    border: none;
    border-radius: 5px;
    font-size: 1rem !important; /* Bruk relative enheter for tekststørrelsen */
    cursor: pointer;
    box-sizing: border-box;
  }

  .save-sc-button {
    background-color: #008489 !important;
    color: #fff;
  } 
  
  .save-sc-button:hover {
    background-color: #006f75 !important;
    color: #fff;
  }
  
  .cancel-sc-button {
    background-color: #dc3545;
    color: #fff;
    border: 1px solid #cc4c4c; /* Mørkere rød ramme */
    transition: background-color 0.3s ease; /* Kun overgang for bakgrunnsfargen */
  }
  
  .cancel-sc-button:hover {
    background-color: #c82333;
    
  }
  