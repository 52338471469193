.agreements-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-box {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.agreement-card {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.accordion-button {
  background-color: #008489;
  color: white;
  padding: 1rem;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-button:hover {
  background-color: #006f75;
}

.date-span {
  font-size: 1rem;
  color: white;
}

.agreement-details {
  padding: 1rem;
}

.agreement-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.agreement-body {
  display: flex;
  justify-content: space-between;
}

.agreement-body-left {
  flex: 1;
}

.agreement-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.agreement-footer-button {
  background: none!important;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;
}

.agreement-footer-button:hover {
  background: #006f75;
}

.pdf-icon {
  margin-right: 0.5rem;
}
