.upload-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-image-preview {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
}

.camera-icon {
  font-size: 40px;
  color: #ccc;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-required {
  border-color: red;
}

.input-valid {
  border-color: green;
}

.upload-button {
  background-color: #28a745;
}

.upload-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #dc3545;
  margin-left: 10px;
}

.cancel-button:hover {
  background-color: #c82333;
}

.button:disabled {
  cursor: not-allowed;
  
}

.modal {
  position: absolute;
  top: 100px;
  left: 50%;
  right: auto;
  bottom: 100px;
  transform: translate(-50%, 0);
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: calc(100% - 200px); /* Tar hensyn til header og footer */
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.close-modal-button {
  background-color: #dc3545;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #cc4c4c; /* Mørkere rød ramme */
  transition: background-color 0.3s ease; /* Kun overgang for bakgrunnsfargen */
}

.close-modal-button:hover {
  background-color: #c82333;
}

.uploading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.uploading-overlay p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.button.schedule-button {
  max-width: 150px; /* Begrens bredden på knappen */
  text-align: center; /* Sørg for at teksten er sentrert */
  padding: 5px 10px; /* Juster padding for en mer balansert knapp */
}

.table-cell-right {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* Sørg for at knappen og teksten er vertikalt sentrert */
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.service-card-header {
  display: flex;
  flex-wrap: wrap; /* Tillater innhold å brytes på små skjermer */
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px; /* Mellomrom mellom elementene */
}

.service-card-info {
  flex: 1 1 60%; /* Fyller 60% av bredden på store skjermer */
}

.map-and-button {
  flex: 1 1 35%; /* Fyller 35% av bredden på store skjermer */
  display: flex;
  flex-direction: column; /* Stabler kart og knapp vertikalt */
  align-items: flex-end; /* Flytter innholdet til høyre */
  gap: 8px; /* Mellomrom mellom kartet og knappen */
}

.get-directions-button {
  background-color: #008489;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.get-directions-button:hover {
  background-color: #006f75;
}

/* Media query for små skjermer */
@media (max-width: 768px) {
  .service-card-header {
    flex-direction: column; /* Stabler kartet under teksten */
    align-items: stretch;
  }

  .map-and-button {
    margin-top: 80px; /* Fjerner margin-top for små skjermer */
    align-items: center; /* Sentraliserer innholdet */
    width: 100%; /* Fyller hele bredden */
    gap: 16px; /* Øker mellomrom mellom elementer */
  }
}

.yellow-button {
  background-color: #ffeb3b; /* Lys gul */
  line-height: 1;
  font-weight: lighter;
  border: 1px solid #b0b0b0 !important;/* Tynn grå border */
  display: inline-block;
  color: black;
  border: 1px solid #ddd;
  cursor: pointer;
}

  .yellow-button:hover {
    background-color: #fdd835; /* Subtil mørkere gul */
    border: 1px solid #b0b0b0; /* Tynn grå border */
    color: black;
  }

  /* Grønn knapp */
.green-button {
  background-color: #4caf50; /* Lys grønn */
  border: 1px solid #88a288 !important; /* Tynn grågrønn border */
  font-weight: lighter;
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  max-width: 150px;
  text-align: center;
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Jevn overgang */
}

.green-button:hover {
  background-color: #4caf50; /* Lys grønn */
  border: 1px solid #88a288 !important; /* Tynn grågrønn border */
  font-weight: lighter;
  color: black;
  padding: 5px 10px;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Juster for spacing */
}

.table-cell {
  flex: 1; /* Sørger for at cellene bruker lik plass */
}

.table-cell-right {
  flex: 0; /* Holder teksten og knappen på samme linje */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.service-card-info {
  display: grid;
  grid-template-columns: 1fr 2fr; /* To kolonner */
  gap: 10px; /* Avstand mellom elementene */
  margin-bottom: 20px;
}

.service-card-info strong {
  color: #333; /* Fremhev tekst */
}

.service-card-info div {
  margin-bottom: 5px; /* Avstand mellom rader */
}

.service-card-info h2 {
  grid-column: span 2; /* Overskrift over begge kolonnene */
  margin-bottom: 10px;
}

.map-and-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.get-directions-button {
  width: 100%;
  max-width: 200px;
}

/* Responsivt design for små skjermer */
@media (max-width: 768px) {
  .service-card-info {
    grid-template-columns: 1fr; /* Én kolonne på små skjermer */
  }

  .service-card-info div {
    margin-bottom: 10px; /* Øk avstand mellom rader for bedre lesbarhet */
  }

  .map-and-button {
    align-items: stretch; /* Strekk for å fylle bredde */
  }

  .get-directions-button {
    width: 100%; /* Full bredde på små skjermer */
  }

  .google-map {
    width: 100%; /* Kartet tilpasses full bredde */
    height: 200px; /* Fast høyde for små skjermer */
  }
}

