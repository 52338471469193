.addresses-container {
  padding: 20px;
}

.sort-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.search-box {
  flex: 1; /* Søkeruten tar opp 50% av bredden */
  min-width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.2em;
}

#sort-select {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1.2em;
}

.filter-button {
  padding: 10px 20px;
  background-color: #008489;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  align-self: flex-start; /* Juster knappen til å starte ved toppen */
}

.filter-button:hover {
  background-color: #006f75;
}

.filter-container {
  background-color: #f9f9f9; /* Lys bakgrunn */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd; /* Delelinje */
  padding-bottom: 10px;
}

.address-card-wrapper {
  margin-bottom: 20px;
}

.address-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.address-card:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.distance-info {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

@media (max-width: 768px) {
  .addresses-container {
    padding: 10px;
  }

  .sort-container {
    flex-direction: column;
    align-items: stretch;
  }

  .sort-container .search-box,
  .sort-container #sort-select,
  .sort-container .filter-button {
    width: 100%;
    margin-bottom: 10px;
  }

  #sort-select {
    padding: 10px; /* Innvendig marg for bedre klikkflate */
    border-radius: 5px; /* Runde kanter */
    border: 1px solid #ccc; /* Grense rundt dropdown */
    font-size: 16px; /* Minimum fontstørrelse for å unngå zooming på mobil */
    background-color: #fff; /* Hvit bakgrunn */
    color: #333; /* Tekstfarge */
    appearance: none; /* Fjern standard dropdown-stil */
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23333" viewBox="0 0 24 24"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3C/svg%3E'); /* Tilpasset pil */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    height: 46px; /* Ensartet høyde med andre elementer */
    box-sizing: border-box; /* Inkluder padding i bredde/høyde */
    max-width: 200px; /* Begrens bredden for penere utseende */
  }
  
  #sort-select:focus {
    outline: none; /* Fjern standard fokusramme */
    border-color: #008489; /* Endre kantfarge ved fokus */
    box-shadow: 0 0 3px #008489; /* Svak glød ved fokus */
  }
  

  .address-card {
    padding: 15px;
  }
}

.optimal-route-message {
  margin-bottom: 20px;
}

.optimal-route-message h2 {
  font-size: 24px;
  font-weight: bold;
}

.optimal-route-message p {
  font-size: 16px;
}
