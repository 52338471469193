/* AgreementNew.css */

.agreement-new-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.agreement-new-form {
  display: flex;
  flex-direction: column;
}

.agreement-new-form-group {
  margin-bottom: 15px;
}

.inline-group {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.inline-group .agreement-new-form-group {
  flex: 1;
  min-width: 45%; /* Juster for at feltene skal ha plass */
}

label {
  font-size: 1em;
  margin-bottom: 5px;
  color: #008489;
}

.agreement-input-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.2rem !important; /* Juster til riktig fontstørrelse */
  box-sizing: border-box;
}

.agreement-input-field.mandatory {
  background-color: #ffffe0; /* Lys gul for obligatoriske felt */
}

.input-field.filled {
  background-color: #defade; /* Lys grønn for utfylte felt */
}

textarea {
  resize: vertical;
  font-family: Arial, sans-serif;
  font-size: 1.5em;
  width: 100%;
}

.agreement-new-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.agreement-cancel-button,
.agreement-save-button {
  padding: 15px 25px;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 4px;
  flex: 1;
  margin: 0 10px;
}

.agreement-cancel-button {
  background-color: #ccc;
  color: #333;
}

.cancel-button:hover {
  opacity: 0.8;
}

.agreement-save-button {
  background-color: #008489;
  color: white;
  font-size: 1.5em;
}

.agreement-save-button:hover {
  background-color: #006f75;
}

.agreement-save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
