/* src/components/OppdragInfoBanner.css */
.oppdrag-info-banner {

    
    
    font-size: 16px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 80vh;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
  }
  
  .oppdrag-info-banner p {
    margin: 5px 0;
  }
  
  .banner-loading {
    font-size: 14px;
    color: #999;
  }
  
  .control-point-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 3em;
    color: #008489;
    position: absolute;
    top: 10px;
    right: 0px;
  }
  .control-point-button:hover {
    color: #006f75;
  }
  