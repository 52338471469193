.event-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .event-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    max-width: 90%; /* Tilpass bredde */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    text-align: left;
    position: relative;
  }
  
  .event-modal-info p {
    margin: 10px 0;
    line-height: 1.5; /* Bedre lesbarhet */
  }
  
  .event-modal-editable label {
    display: block;
    margin: 10px 0;
  }
  
  .event-modal-editable input,
  .event-modal-editable textarea,
  .event-modal-editable select {
    width: 100%; /* Gjør feltene like brede */
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .event-modal-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Juster knapper jevnt */
    gap: 10px;
    margin-top: 20px;
  }
  
  .event-modal-buttons button {
    flex: 1;
    min-width: 100px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    text-align: center;
  }
  
  .event-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  .button-disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    border: 1px solid #aaa;
  }

  .button-disabled:hover {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    border: 1px solid #aaa;
  }
  
  .event-modal-buttons button {
    flex: 1;
    min-width: 100px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    text-align: center;
  }
  
  .event-modal-buttons button:not(.button-disabled:hover):hover {
    background-color: #006f75;
    
  }

  .event-description {
    padding: 10px;
    font-size: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    white-space: pre-wrap; /* Behold linjeskift i beskrivelsen */
    margin-top: 5px;
  }
  
  /* Deaktivert input og select */
.input-disabled {
  background-color: #f5f5f5;
  color: #a0a0a0;
  cursor: not-allowed;
  border: 1px solid #ccc;
}
  
  /* Responsivt design */
  @media (max-width: 768px) {
    .event-modal-content {
      width: 95%;
      padding: 15px;
    }
  
    .event-modal-buttons {
      flex-direction: column;
    }
  
    .event-modal-buttons button {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .event-modal-content {
      font-size: 0.9rem;
    }
  
    .event-modal-buttons button {
      font-size: 0.85rem;
    }
  }
  