.service-address-new-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-height: calc(90vh - 40px); /* Tilpasser høyden på skjemaet */
    
}

.input-field {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.input-field label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.input-field input,
.input-field select,
.input-field textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    color: #333;
}

.input-field select {
    padding: 10px; /* Innvendig marg for bedre klikkflate */
    font-size: 16px; /* Juster fontstørrelsen for bedre lesbarhet */
    border: 1px solid #ccc; /* Grense rundt dropdown */
    border-radius: 4px; /* Runde kanter */
    background-color: #fff; /* Hvit bakgrunn */
    color: #333; /* Tekstfarge */
    appearance: none; /* Fjern standard utseende for dropdown */
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23333" viewBox="0 0 24 24"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3C/svg%3E'); /* Legg til en egendefinert pil */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px

}



.form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 60px; /* Legger til padding under knappene */
}

.save-button,
.cancel-button {
    padding: 15px 25px;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    border-radius: 4px;
    flex: 1;
    margin: 0 10px;
}

.save-button {
    background-color: #008489;
    color: white;
}

.save-button:hover {
    background-color: #006f6b;
}

.cancel-button {
    background-color: #ff4c4c;
    color: white;
}

.cancel-button:hover {
    background-color: #e04444;
}

.save-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.custom-modal {
    width: 100%; /* Gjør at modalen fyller 90% av skjermbredden på små skjer */
    overflow-y: auto; /* Aktiver scrolling kun i modalen */
    -webkit-overflow-scrolling: touch; /* For jevn scrolling på iOS */
    max-height: 90vh; /* Sørger for at modal ikke overstiger skjermhøyden */
    margin: auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.custom-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.service-address-cancel-button,
.service-address-save-button {
  padding: 15px 25px;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 4px;
  flex: 1;
  margin: 0 10px;
}
