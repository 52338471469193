.editor-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px; /* Legg til mellomrom mellom elementene i flex-containeren */
  }
  
  .tool-panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* margin-right: 10px;  Fjern denne marginen, da vi nå bruker gap på .editor-container */
    width: 60px;
  }
  
  .canvas-stage {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 auto; /* Center canvas */
    max-width: 80%; /* Ensure canvas is responsive */
    max-height: 100%; /* Ensure canvas is responsive */
    flex-grow: 1; /* La lerretet vokse for å fylle tilgjengelig plass */
  }
  
  
  .text-input {
    margin-top: 10px;
  }
  
  .save-button {
    background-color: #008489;
    color: #fff;
    border: none;
  }
  
  .save-button:hover {
    background-color: #006f75;
    color: #fff;
  }
  
  .cancel-button {
    background-color: #ff6666;
    color: #fff;
    border: 1px solid #cc4c4c; /* Mørkere rød ramme */
    transition: background-color 0.3s ease; /* Kun overgang for bakgrunnsfargen */
  }
  
  .cancel-button:hover {
    opacity: 0.8;
  }

  /* Deaktiver dobbeltrykk for å zoome på mobile enheter */
canvas, body, html {
    touch-action: none;
    -ms-touch-action: none;
}

/* Sikrer at lerretet ikke forstyrrer zoom */
canvas {
    width: 100%;
    height: auto;
    max-width: 100%;
    touch-action: manipulation;
    user-select: none;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5); /* Angi en mørk bakgrunnsfarge med 50% opasitet */
  }