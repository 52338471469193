/* customeroverview.css */

.custom-container {
  padding: 5px;
}

.custom-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.custom-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-card-info {
  flex: 1;
}

.custom-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  width: 500px;
  margin: auto;
  border: 1px solid #ccc;
}

.custom-modal h2 {
  text-align: center;
  margin-bottom: 20px;
}

.custom-input-field {
  margin-bottom: 15px;
}

.custom-input-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.custom-input-field input {
  width: 100%;
  max-width: 480px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
}

.custom-input-field input:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}

.custom-modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.custom-modal-buttons .button {
  width: 48%;
}

.error {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-accordion-section {
  margin-top: 20px;
}

.custom-accordion-button {
  width: 100%;
  background-color: #008489;
  color: white;
  padding: 10px;
  border: none;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
}

.custom-accordion-button:hover {
  background-color: #006f75;
}

.custom-accordion-content {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.custom-accordion-item {
  margin-bottom: 10px;
}

.custom-accordion-item-content {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}

.custom-address-button {
  background-color: white;
  color: #008489;
  border: 1px solid #008489;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.custom-address-button:hover {
  background-color: #008489;
  color: white;
}

@media (max-width: 600px) {
  .custom-modal {
    max-width: 95%;
    padding: 15px;
  }

  .custom-modal-buttons {
    flex-direction: column;
    align-items: center;
  }

  .custom-modal-buttons .button {
    width: 100%;
    margin-bottom: 10px;
  }
}
