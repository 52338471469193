.driving-routes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .driving-route-button {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    background-color: #008489;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5em;
    cursor: pointer;
    text-align: left;
  }
  
  .driving-route-button:hover {
    background-color: #006f6b;
  }
  