/* NewInspection.css */

.ins-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ins-field, .ins-field-row {
  margin-bottom: 15px;
}

.ins-field-row, .ins-field-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ins-flex-1 {
  flex: 1;
  margin-right: 10px;
}

.ins-flex-2 {
  flex: 2;
  margin-right: 10px;
}

.ins-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.5em;
  box-sizing: border-box;
}

.ins-input-required {
  background-color: #ffffe0; /* Lys gul for obligatoriske felt */
}

.ins-input-filled {
  background-color: #defade; /* Lys grønn for utfylte felt */
}

.ins-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.ins-button {
  padding: 15px 25px;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 4px;
  flex: 1;
  margin: 0 10px;
}

.ins-button-cancel {
  background-color: #ff6666;
  color: #fff;
  border: 1px solid #cc4c4c; /* Mørkere rød ramme */
  transition: background-color 0.3s ease; /* Kun overgang for bakgrunnsfargen */
}

.ins-button-next {
  background-color: #008489;
}

.ins-button:hover {
  opacity: 0.8;
}

.ins-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

label {
  color: #008489;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  font-size: 1.2em; /* Ensartet fontstørrelse */
}

.select-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.5em;
}

@media (max-width: 600px) {
  .ins-field-inline {
    flex-direction: column;
  }

  .ins-buttons {
    flex-direction: column;
    gap: 10px;
  }
}
