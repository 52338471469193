.nwo-form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #008489;
}

.nwo-form-group {
  margin-bottom: 15px;
}

.nwo-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #008489;
}

.nwo-form-group input[type="text"],
.nwo-form-group input[type="email"],
.nwo-form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.2rem;
  box-sizing: border-box;
}

.nwo-required {
  background-color: #ffffe0 !important; /* Light yellow for required fields */
}

.nwo-valid {
  background-color: #defade !important; /* Light green for valid fields */
}

.nwo-invalid {
  background-color: #ffd4d4 !important; /* Light red for invalid fields */
}

.nwo-non-mandatory {
  background-color: #fff !important; /* White for non-required fields */
}

.nwo-small-group {
  margin-bottom: 15px; /* Legg til litt plass mellom feltene */
}

.nwo-small-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #008489;
}

.nwo-small-group input[type="text"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.2rem;
  box-sizing: border-box;
}

.nwo-button-group {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Legger til mellomrom mellom knappene */
}

.nwo-cancel-button,
.nwo-next-button {
  flex: 1; /* Sørger for at begge knappene tar opp like mye plass */
  padding: 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nwo-cancel-button {
  background-color: #ccc;
  color: #333;
}

.nwo-cancel-button:hover {
  opacity: 0.8;
}

.nwo-next-button {
  background-color: #008489;
  color: white;
}

.nwo-next-button:hover {
  background-color: #006f75;
}
