.custom-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .custom-input-field {
    margin-bottom: 20px;
  }
  
  .custom-input-field label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .custom-input-field input,
  .custom-input-field select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .custom-modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .custom-modal-buttons .button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .custom-modal-buttons .upload-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .custom-modal-buttons .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .error {
    color: red;
    margin-top: 5px;
  }
  