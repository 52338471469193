/* ServiceAddressNew.css */

/* Eksisterende CSS-stiler her ... */

.service-address-new-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-address-new-form-group {
  margin-bottom: 15px;
}

.service-address-new-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #008489;
  font-size: 1.2em;
}

.service-address-new-form-group input[type="text"],
.service-address-new-form-group input[type="email"],
.service-address-new-form-group input[type="tel"],
.service-address-new-form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.5em;
  box-sizing: border-box;
}

.service-address-new-form-group.inline-group {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.service-address-new-form-group.street-address {
  flex: 2;
}

.service-address-new-form-group.apt-no {
  flex: 1;
}

.service-address-new-form-group.postal {
  flex: 1;
}

.service-address-new-form-group.city {
  flex: 2;
}

/* Legg til disse klassene for obligatorisk og gyldig input */
.newsa-input-field.mandatory {
  background-color: #ffffe0;
}

.newsa-input-field.filled {
  background-color: #defade;
}

.newsa-input-field.invalid {
  background-color: #ffd4d4;
}

.service-address-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.service-address-cancel-button,
.service-address-save-button {
  padding: 15px 25px;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 4px;
  flex: 1;
  margin: 0 10px;
}

.service-address-cancel-button {
  background-color: #ccc;
  color: #333;
}

.service-address-cancel-button:hover {
  opacity: 0.8;
}

.service-address-save-button {
  background-color: #008489;
  color: white;
}

.service-address-save-button:hover {
  background-color: #006f75;
}

.service-address-save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .service-address-new-form-group.inline-group {
    flex-direction: column;
  }

  .service-address-button-group {
    flex-direction: column;
  }
}
