.control-point-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2000; /* Justert z-index for å sikre at modalen vises over annet innhold */
}

.control-point-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.button.close-button {
  background: #d9534f;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid #cc4c4c !important; /* Mørkere rød ramme */
  transition: background-color 0.3s ease; /* Kun overgang for bakgrunnsfargen */
}

.button.close-button:hover {
  background: #c9302c;
}

.checkpoint-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  max-width: 400px;
  width: 100%;
}
.accordion-cp-button {
  background-color: #d3d3d3;
  color: #444;
  padding: 10px;
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
  font-size: 1rem;
}

.accordion-cp-button:hover {
  background-color: #a9a9a9;
}
.checkpoint-details {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ccc;
}